
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_tag helper in views (e.g <%= image_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from '@rails/ujs';
Rails.start()

import jQuery from 'jquery';

import '@hotwired/turbo-rails'

import 'controllers'
import * as bootstrap from 'bootstrap'
import "@fortawesome/fontawesome-free"

window.bootstrap = bootstrap
global.bootstrap = bootstrap

require("@rails/activestorage").start()
require("channels")
require('./nested-forms/addFields')
require('./nested-forms/removeFields')

import "../stylesheets/application"

// Cropper
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'
import "jquery-cropper"

import "html2canvas"

import "dhtmlx-gantt"; // Esto incluye la librería principal y el CSS por defecto
import "dhtmlx-gantt/codebase/dhtmlxgantt.css"; // Asegúrate de importar el CSS

// Chart.js
import Chart from 'chart.js/auto'

// Leaflet maps
import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css' // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility'
import 'leaflet-rotate'
window.L = L

// Leaflet MarkerCluster
import "leaflet.markercluster"

// ESRI Leaflet
import * as esri from 'esri-leaflet'
window.L.esri = esri
import * as esriGeocoder from 'esri-leaflet-geocoder'
window.L.esri.Geocoding = esriGeocoder

// Google Mutant
import * as googlemutant from 'leaflet.gridlayer.googlemutant'
window.googlemutant = googlemutant

// Tagify
import Tagify from '@yaireo/tagify'

// Importar FullCalendar y plugins
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import "@fortawesome/fontawesome-free/css/all"
global.toastr = require("toastr")

import "inputmask"
import ClipboardJS from "clipboard"
var moment = require('moment')
import "daterangepicker"

// import "bootstrap-daterangepicker"
import Iconpicker from 'codethereal-iconpicker'
global.XLSX = require("xlsx")

import { Tooltip, Popover } from "bootstrap"

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery
window.Tagify = Tagify
window.Cropper = Cropper
window.Tooltip = Tooltip

import Bowser from "bowser"
window.Bowser = Bowser

// Select2
import "../select2/select2.min"
import TomSelect from "tom-select"

// include moment in global and window scope (so you can access it globally)
global.moment = moment;
window.moment = moment;

document.addEventListener("turbo:load", function(event) {

  // Add this code to set the X-CSRF-Token header
  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });

  Inputmask().mask(document.querySelectorAll("input"));

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })

  let clipboard = new ClipboardJS('.btn-clip');

  clipboard.on('success', function (e) {

    var clipboardToastEl = document.getElementById('clipboardToast');
    var clipboardToast = new bootstrap.Toast(clipboardToastEl);

    clipboardToast.show();

    setTimeout(() => {
      clipboardToast.hide();
    }, 2000);
  });


  var timer;
  $(".filterrific-periodically-observed").on('keyup change', function(){
    clearTimeout(timer);
    var ms = 500;
    var form = $(this).parents("form"), url = form.attr("action");
    timer = setTimeout(function() {
      $.ajax({
        url: url,
        data: form.serialize(),
        type: 'GET',
        dataType: 'script',
        beforeSend : function()
        {
          $('#main_spinner').show();
        }
      }).done(function() {
          $("#main_spinner").hide()
      });
    }, ms);
  })

  $("#main_spinner").hide();
});

document.addEventListener('turbo:submit-start', function() {
  document.getElementById('main_spinner').style.display = 'block';
});

document.addEventListener('turbo:submit-end', function() {
  document.getElementById('main_spinner').style.display = 'none';
});

function displayToastrFlash() {
  if(flash.any()) {
    flash.each(function(key, value) {
      var type = key.toString().replace('alert', 'error').replace('notice', 'success');
      toastr[type](value);
    });
  }
}

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
                         .then(function(registration) {
                           console.log('[Companion]', 'Service worker registered!')
                           console.log(registration)
                         })
}

import "./service_worker"
